@import '../../../assets/scss/_master.scss';

media-editor {
  .quick-add-btn {
    margin-top: 10px;
  }

  .drag-placeholder {
    background: #ccc;
    border: dotted 3px #999;
    min-height: 50px;
    width: 350px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drag.row {
    margin-left: 8px;
    display: flex;
    align-items: center;

    &.marked-for-deletion {
      display: none;
    }

    .btn {
      margin-left: 8px;
    }
  }

  .cdk-drag-handle.btn {
    margin-left: -16px !important;
  }

  .item {
    width: 350px;
    display: flex;
    align-items: baseline;
  }

  image-upload {
    .images-container .image-card {
      border: none;
    }
  }

  .media-form > .row {
    margin-bottom: 1rem;
  }

  #preview {
    .modal {
      width: 75vw;
      height: 75vh;
      max-width: none;
      max-height: none;

      .image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .primary-checkbox {
    margin-left: 10px;
  }
}

// must be global
.drag-preview {
  display: flex;
  align-items: center;

  .btn {
    background-color: transparent;
    margin-right: 8px;
    .icon {
      width: 20px;
      height: 20px;

      svg path {
        fill: $pacificBlue;
      }
    }
  }
}

.date-modified {
  display: inline-block;
  margin-left: 1rem;
}

.main-section .v2 .bottom .form-box media-editor .form-box:last-of-type {
  margin-bottom: 0;
}
