@import "../../../assets/scss/_master.scss";

expanding-set {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    .field {
        padding-bottom: 10px;
    }
}
