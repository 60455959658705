@import '../../../assets/scss/master';

icon-picker {
  display: block;
  max-height: 85vh;
  overflow-y: auto;
  @include styled-scrollbar();

  // when used in a modal, we need to override the modal's btn margin
  .btn.quick.expander {
    margin: 0 10px 0 0 !important;
  }

  .entity-editor-container {
    background-color: $color2;
    padding: 1rem;

    section.entity-editor:last-child {
      margin-bottom: 0 !important;
    }
  }

  .entity-editor {
    background-color: $color14;
    border-bottom-right-radius: 8px;
    margin-bottom: 1rem;
    border: 1px solid $color2;

    &.open {
      & > header {
        .expander {
          background-color: $color4;

          &:hover {
            background-color: darken($color4, 15%);
          }

          .icon {
            @include transform(rotate(270deg) scale(-1));

            svg path {
              fill: $color8;
            }
          }
        }
      }

      & > .entity-form-content {
        max-height: none;
      }
    }

    .entity-form-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 200ms ease-in;

      .form-container {
        padding: 1rem;

        & > h4 {
          color: $color3;
          text-align: center;
          margin: 5px 0;
        }
      }
    }

    .entity-row-item {
      padding: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      align-items: center;
      overflow: hidden;
      border-bottom: 1px solid $color2;

      .drag-handle {
        border-right: 2px solid $color3;
        border-radius: 0;
        margin-right: 8px;
        padding: 0;

        .icon {
          pointer-events: none;
          margin-right: 8px;
        }
      }

      .expander {
        margin-right: 10px;
        background-color: $pacificBlue;

        &:hover {
          background-color: darken($pacificBlue, 15%);
        }

        &.disabled {
          opacity: 0.3;
        }

        .icon {
          @include transform(rotate(270deg) scale(1));
          @include transition(transform 300ms ease-in-out);

          svg {
            path,
            polygon {
              fill: $color8;
            }
          }
        }
      }

      h4 {
        margin: 0;
      }

      .warning {
        width: 20px;
        height: 20px;
        margin-left: 8px;

        svg {
          fill: $errorText;
        }
      }
    }
  }

  .icon-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 1rem 1rem;

    .icon {
      padding: 1rem;
      border: 1px solid gray;
      background-color: white;
      border-radius: 3px;
      margin-right: 2.5%;
      margin-top: 2.5%;
      width: 17.5%;

      &:hover,
      &:active {
        background-color: darken($color: white, $amount: 15%);
      }

      &.selected,
      &:active {
        border: 2px solid $pacificBlue;
      }

      .preview {
        width: 30px;
        height: 30px;
        margin: auto;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .icon-label {
        margin-top: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
