import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { User, UserService } from '../entities';
import { ArgoGateway } from '../shared/argo.gateway';
import { Lookup } from '../shared/lookup';
import { GoogleTagManagerService } from '../utility/google-tag-manager.service';

@Injectable()
export class AuthService {
  private loggedIn = false;
  private impersonating = false;
  currentUser: User;
  redirectUrl: string;
  private manualAuthClient: HttpClient;

  constructor(
    private argoGateway: ArgoGateway,
    private router: Router,
    private location: Location,
    private http: HttpClient,
    private userService: UserService,
    private googleTagManager: GoogleTagManagerService,
    handler: HttpBackend
  ) {
    this.manualAuthClient = new HttpClient(handler);
    this.loggedIn = !!localStorage.getItem(Lookup.Auth.HmacTokenKey);
    this.impersonating = !!localStorage.getItem(Lookup.Auth.HmacImpersonatedByKey);
  }

  logout() {
    this.loggedIn = false;
    this.impersonating = false;
    this.currentUser = null;
    this.clearGTMUserValues();
    localStorage.removeItem(Lookup.Auth.HmacTokenKey);
    localStorage.removeItem(Lookup.Auth.CurrentUserKey);
    localStorage.removeItem(Lookup.Auth.HmacExpiresKey);
    localStorage.removeItem(Lookup.Auth.HmacImpersonatedByKey);
    this.router.navigate(['/login']);
  }

  login(email: string, password: string): Observable<User> {
    // determine local time tomorrow at 3am
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(3, 0, 0, 0);

    const hoursTilExpiration = Math.floor(
      (tomorrow.getTime() - new Date().getTime()) / 1000 / 60 / 60
    );

    const body = {
      UserName: email,
      Password: password,
      HoursTilExpiration: hoursTilExpiration
    };

    return this.http.post<User>(`/api/users/login`, body).pipe(
      tap((u: User) => {
        const user = new User(u);
        this.setUserCredentialsFromAPI(user);
        return this.currentUser;
      })
    );
  }

  sessionTimeout() {
    if (this.loggedIn) {
      this.saveUrlForRedirect();
      this.logout();
    }
  }

  saveUrlForRedirect() {
    this.redirectUrl = this.location.path();
  }

  setUserCredentialsFromAPI(user: User) {
    this.loggedIn = true;
    this.currentUser = new User(user);
    this.initializeGTMUserValues(this.currentUser);
    localStorage.setItem(Lookup.Auth.CurrentUserKey, this.currentUser.UserName);
  }

  impersonateLogin(email: string): Observable<User> {
    const body = {
      UserName: email,
      Password: ''
    };

    return this.http.post<User>(`/api/users/impersonatelogin`, body, { observe: 'response' }).pipe(
      map((res: HttpResponse<User>) => {
        this.loggedIn = true;
        this.currentUser = new User(res.body);
        this.initializeGTMUserValues(this.currentUser);
        localStorage.setItem(Lookup.Auth.CurrentUserKey, this.currentUser.UserName);
        localStorage.setItem(
          Lookup.Auth.HmacImpersonatedByKey,
          res.headers.get(Lookup.Argo.Headers.HmacImpersonatedBy)
        );
        return this.currentUser;
      })
    );
  }

  initializeGTMUserValues(currentUser: User): any {
    const getClientId = (user: User) => {
      if (user.isSuperAdmin || user.ClientId === 4) return 0;
      if (user.ClientId) return user.ClientId;
      return '';
    };

    const getClientName = (user: User) => {
      if (user.isSuperAdmin || user.ClientId === 4) return 'MediaLab';
      if (user.ClientName) return user.ClientName;
      return '';
    };

    this.googleTagManager.push({
      clientId: getClientId(currentUser),
      clientName: getClientName(currentUser),
      userId: currentUser.Id
    });
  }

  clearGTMUserValues(): void {
    this.googleTagManager.push({
      clientId: '',
      clientName: '',
      userId: ''
    });
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  isImpersonating(): boolean {
    return this.impersonating;
  }

  // Attempts to load user based on cached username
  loadCurrentUser(): Observable<User> {
    // if username cached then go get it
    const cachedUsername = localStorage.getItem('current_username');
    if (cachedUsername) {
      return this.userService.getByUsername(cachedUsername).pipe(
        map((user: User) => {
          if (!user.IsActive) throw new Error('User not found');

          this.currentUser = user;
          this.initializeGTMUserValues(user);
          return user;
        }),
        catchError((error: Error) => {
          return observableThrowError(`Could not load the current user data. Most likely
                    the session timed out and re-authentication is needed.`);
        })
      );
    } else {
      return observableThrowError('No username found in local storage');
    }
  }

  isSuperAdmin(): boolean {
    return this.currentUser.isSuperAdmin;
  }

  isClientAdmin(): boolean {
    return this.currentUser.hasRole(Lookup.Modules.UserManagement.AlternateRoles.clientAdmin);
  }

  isDivisionAdmin(): boolean {
    return this.currentUser.hasRole(Lookup.Modules.UserManagement.AlternateRoles.divisionAdmin);
  }

  isStandardUser(): boolean {
    return this.currentUser.hasRole(Lookup.Modules.UserManagement.AlternateRoles.standard);
  }

  sendPasswordEmail(email: string) {
    return this.http.post(`/api/email/forgot-pw-email?userEmail=${email}`, { email });
  }

  updatePasswordFromEmailToken(
    userName: string,
    token: string,
    newPassword: string
  ): Observable<User> {
    const url = this.argoGateway.fullBaseDomain(`/api/users/${userName}/${token}`);
    const authHeaders = this.argoGateway.getHeadersWithAdminUser(url, 'PATCH');

    return this.manualAuthClient.patch<User>(
      url,
      { UserName: userName, Password: newPassword },
      {
        headers: authHeaders
      }
    );
  }

  isValidToken(userName: string, token: string): Observable<void> {
    const url = this.argoGateway.fullBaseDomain(`/api/users/${userName}/${token}`);
    const authHeaders = this.argoGateway.getHeadersWithAdminUser(url, 'GET');

    return this.manualAuthClient.get<void>(url, { headers: authHeaders });
  }
}
