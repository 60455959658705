@import "../../../assets/scss/_master.scss";


.main-section .v2 bulk-location {

    .btn {
        &.inactive {
            pointer-events: none;
        }
    }

    .locked-label {
        background-color: white;
        padding: 3px 5px;
        border: 1px solid #e1e1e1;
        border-radius: 4px;
        margin: 0;
        height: 30px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-content: center;
        align-items: center;
    }

    .row {
        padding-bottom: 8px;
        justify-content: flex-start;

        &:last-child {
            border: none;
            padding-bottom: 0;
        }

        .row-cell {
            margin-top: 5px;
            align-items: flex-end;
        }

        .location-container {
            padding: 20px 0;
            border-bottom: 2px dashed $rowcolor4;
        }
    }
}
