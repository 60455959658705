@import '../../../assets/scss/master';

.hotspot-marker {
  position: absolute;
  top: 0;
  left: 0;
  height: 75px;
  width: 75px;
  border-radius: 50% 50% 50% 0;
  background: $pacificBlue;
  transform: rotate(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  & > svg-helper {
    transform: rotate(45deg);
    height: 50px;
    width: 50px;
    z-index: 5;
    path {
      fill: #fff;
    }
  }

  &.selected {
    background-color: lighten($pacificBlue, 30%);

    & > svg-helper {
      path {
        fill: $pacificBlue;
      }
    }
    .radar {
      & > svg-helper {
        path {
          fill: lighten($pacificBlue, 30%);
        }
      }
    }
  }

  &.full-circle {
    border-radius: 50%;
  }

  .radar {
    position: absolute;
    height: 225px;
    width: 225px;
    opacity: 0.75;
    pointer-events: none;
    transform: rotate(45deg);
    z-index: 4;
    & > svg-helper {
      path {
        fill: $pacificBlue;
      }
    }
  }
}
