
can-deactivate-modal {

    modal {
        display: none;
    }

    modal.visible {
        display: block;
    }
}