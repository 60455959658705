@import '../../../assets/scss/_master.scss';

cascading-dropdowns .container {
  display: flex;
  align-items: center;
  width: auto;

  dropdown {
    display: block;

    & + dropdown {
      margin-left: 1rem;
    }

    .title {
      margin-bottom: 0.5rem;
    }
  }
}
