.report-overlay-container{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    top: 0;
    left: 101%;
    border: 2px solid #e4e4e4;
    border-top: none;
    transition: left 400ms;
    &.open {
        left: 0%;
    }
}

#embed-container-page {
    height: 100%;
}