code-editor {
  position: relative;
  display: block;

  .codeflask textarea::selection {
    background-color: lightgray;
  }
}

// reapplying CodeFlask styles that are overridden from the Converge form
.main-section .v2 .bottom .form-box .row .field .codeflask textarea {
  width: calc(100% - 40px);
  height: 100%;
  padding: 10px;
  font-size: 13px;
  white-space: pre;
  border: none;
}
