@import '../../../assets/scss/_master.scss';

multi-expanding-set {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: -10px;

  .multi-separator-container {
    display: flex;
    justify-content: center;
    padding-right: 32px;
    margin-bottom: 25px;

    .multi-separator {
      height: 2px;
      border-top: 1px solid $color14;
      width: 75%;
    }
  }

  .field {
    padding-bottom: 10px;

    &.set {
      margin-bottom: 35px;
    }

    &.new {
      margin-bottom: 20px;
    }
  }

  .inputs-container {
    padding: 0 6px;
    border-bottom: 1px dashed $color14;
    &.multi {
      &.added {
        display: flex;
        .btn-right {
          display: flex;
          align-items: flex-start;
        }
      }

      display: initial;
      align-items: initial;

      input {
        margin-top: 10px;
      }

      .input-top {
        display: flex;
      }

      .inputs-left,
      .inputs-left > form {
        display: flex;
        flex-direction: column;
        width: 100%;
        .label {
          margin-top: 10px;
          span {
            padding-right: 6px;
          }
          input {
            vertical-align: middle;
          }
        }
      }

      .inputs-right {
        display: flex;
        align-items: flex-start;
      }

      .standard-negative,
      .standard-positive {
        margin-top: 40px;
      }

      .standard-positive {
        &.btn {
          &.quick {
            margin-left: 15px;
          }
        }
      }

      .alert-danger {
        color: $errorText;
      }
    }
  }
}
