@import '../../../assets/scss/_master.scss';

.modal-backdrop {
  .modal {
    // sadly need to dupe CSS from default-v2.scss for base btn due to scoping issues
    // long-term TODO is to fix v2 scoping since now marjority of the site is onto that format
    .btn {
      cursor: pointer;
      @include user-select(none);
      text-align: left;
      @include transition(background-color 0.2s ease-in);
      @include box-sizing(border-box);
      color: $color8;
      padding: 8px 16px;
      font-size: $content1;
      font-weight: 500;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;

      &:active {
        @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
      }

      &.standard-positive {
        background-color: $pacificBlue;

        &:hover {
          background-color: darken($pacificBlue, 15%);
        }

        &.disabled {
          opacity: 0.5;
        }
      }

      &.priority-positive {
        background-color: $turtleGreen;

        &:hover {
          background-color: darken($turtleGreen, 15%);
        }
      }

      &.standard-active {
        background-color: $color4;

        &:hover {
          background-color: darken($color4, 15%);
        }
      }

      &.standard-negative {
        background-color: $color1;

        &:hover {
          background-color: lighten($color1, 15%);
        }
      }
    }

    .buttons {
      margin-top: 30px;

      .btn {
        background-color: $color6;
        color: $color5;
        display: inline-block;
        width: 185px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: $sub-head2;
        opacity: 1;
        @include transition(opacity, background-color 200ms ease-in);

        &:first-child {
          margin-right: 36px;
        }

        &:hover {
          background-color: $color7;
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}
