@import '../../../assets/scss/_master.scss';

.toast-container {
  @include fixed(top 0 bottom 0 left 0 right 0);
  z-index: 6; // above the modal component
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  flex-wrap: wrap;

  &.blocking {
    pointer-events: auto;
  }

  .backdrop {
    background-color: rgba($color1, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    max-width: 450px;
    min-height: 200px;
    border-radius: 8px;
    color: $color8;
    padding: 25px;
    text-align: center;
    @include transition(background-color 175ms ease-in);

    &.success {
      background-color: rgba($turtleGreen, 0.85);
    }

    &.error {
      background-color: rgba($errorText, 0.85);
    }

    .icon {
      width: 40px;
      height: 40px;

      svg {
        path {
          fill: $color8;
        }
      }
    }

    .title {
      width: 100%;
      font-size: $sub-head2;
      margin-top: 10px;
    }

    .message {
      font-size: $content2;
      margin-top: 8px;
      width: 100%;
      white-space: pre-wrap;
      user-select: none;
    }
  }

  $dotSize: 20px;
  $animDelay: 0.3s;
  .threedotloader {
    width: $dotSize * 4;

    .dot {
      width: $dotSize;
      height: $dotSize;
      margin: 0 3px;
      border-radius: $dotSize;
      background-color: $color8;
      transform: scale(0);
      display: inline-block;
      animation: dotanim 2s infinite ease-in-out;
      @for $i from 0 to 4 {
        &:nth-child(#{$i + 1}) {
          animation-delay: $animDelay * $i;
        }
      }
    }
  }
}
