@import "../../../assets/scss/master";

$background: rgba($color1, 0.7);

gallery {
  position: relative;
  display: block;
  @include flex-center-center();

  .loader {
    @include absolute(top 0 right 0 bottom 0 left 0);
    z-index: 2;
    background-color: #f0f0f0b7;
    @include flex-center-center();
  }

  header {
    @include absolute(top 0);
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    &.has-title {
      background-color: $background;
      color: #fff;
    }
  }

  img,
  video,
  .img-placeholder {
    width: 100%;
    height: 100%;
    background-color: gray;
    @include animation-fadeIn();
  }

  img {
    object-fit: cover;
    object-position: center;
  }

  .swiper-container {
    width: 100%;
  }

  .left-arrow,
  .right-arrow {
    background-color: $background;
    position: absolute;
    z-index: 2;
    top: calc(50% - 8px);
    @include flex-center-center();
    padding: 0.5rem 1rem;
    cursor: pointer;

    svg-helper {
      width: 1rem;
      height: 1rem;

      svg {
        width: 100%;
        height: 100%;

        path,
        polygon {
          fill: white;
        }
      }
    }

    &.left-arrow {
      left: 0;
      transform: rotate(180deg);
    }

    &.right-arrow {
      right: 0;
    }
  }

  .fullscreen-btn {
    top: auto;
    @include absolute(bottom 0 right 0);
    z-index: 2;
  }

  .pager {
    padding: 0 1.4rem; // account for fullscreen btn
    width: 100%;
    position: absolute;
    bottom: 0;
    @include flex-center-center();
    flex-wrap: wrap;

    .page-number {
      background-color: $background;
      padding: 0.15rem;
      flex-shrink: 0;
      color: #fff;
      margin: 0 0.5rem;
      margin-bottom: 5px;
    }
  }
}
