@import "../../../assets/scss/_master.scss";

.modal-backdrop {
    @include fixed(top 0 left 0 right 0 bottom 0);
    z-index: 5;
    background-color: $modalBackgroundColor;
    @include animation(fadeIn 200ms ease-out);

    .modal {
        width: 500px;
        @include align-both-directions();
        background-color: $color8;
        border-radius: 8px;
        padding: 45px;
        @include box-sizing(border-box);        

        .close {
            width: 40px;
            height: 40px;
            background-color: $color8;
            border-radius: 50%;
            border: 1px solid $color3;
            @include absolute(top -20px right -20px);

            .icon {
                width: 15px;
                height: 15px;
                @include align-both-directions();

                svg path {
                    fill: $color3;
                }
            }
        }
    }
}
