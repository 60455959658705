@import '../../../assets/scss/master';

tag-editor {
  input,
  label {
    display: block;
  }

  label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .input-container {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 8px;
    }
  }

  svg-helper {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .assigned-tags {
    margin-top: 1rem;

    ul {
      padding: 0;
      margin-top: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li.tag {
        padding: 2px;
        padding-left: 0.75rem;
        margin-top: 1rem;
        margin-right: 0.75rem;
        background-color: $color1;
        color: $color8;
        border-radius: 5px;
        display: flex;
        align-items: center;

        .btn.quick {
          margin-left: 0.75rem;
        }
      }
    }
  }
}

.mat-mdc-autocomplete-panel h4 {
  padding-left: 16px;
}
