@import '../../../assets/scss/master';

loading-indicator {
  background-color: rgba($color1, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;

  &.top-align {
    align-items: flex-start;
    padding-top: 3rem;
  }

  $dotSize: 20px;
  $animDelay: 0.3s;
  .threedotloader {
    width: $dotSize * 4;

    .dot {
      width: $dotSize;
      height: $dotSize;
      margin: 0 3px;
      border-radius: $dotSize;
      background-color: $color8;
      transform: scale(0);
      display: inline-block;
      animation: dotanim 2s infinite ease-in-out;
      @for $i from 0 to 4 {
        &:nth-child(#{$i + 1}) {
          animation-delay: $animDelay * $i;
        }
      }
    }
  }
}
