@import '../../../assets/scss/_master.scss';

.quick-btn {
  margin-bottom: 1rem;
  width: 210px;
  display: inline-flex;
  justify-content: center;
}

.quick-add-btn {
  margin-top: 10px;
}

.quick-drag-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 50px;
  width: 350px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.quick-drag-preview {
  .btn {
    background-color: transparent;
    margin-right: 8px;
    .icon {
      width: 20px;
      height: 20px;

      svg path {
        fill: $pacificBlue;
      }
    }
  }
  .quick-btn {
    background-color: $pacificBlue;
    width: 210px;
    height: 32px;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    text-align: left;

    .icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;

      svg path {
        fill: white;
      }
    }
    .label {
      display: block;
      font-size: 16px;
    }
  }
}
