@import '../../../assets/scss/master';

accordion {
  display: block;

  .accordion-container {
    width: 100%;
    background-color: $color8;
    color: $color1;
    box-shadow: 0 4px 7px #95989a;
    overflow: hidden;

    &.open {
      & > .accordion-header {
        .arrow {
          .plus :first-child > svg {
            transform: rotate(0deg);
            transition: transform 0.2s ease-in;
          }
          .carrot-arrow {
            transform: rotate(90deg);
            transition: transform 0.2s ease-in;
          }
        }
      }

      & > .accordion-body {
        border-top: 1px dotted $color3;
      }
    }

    &.no-collapse {
      favorite-btn {
        margin-right: 0;
      }
    }

    .arrow {
      position: relative;

      svg-helper > svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .accordion-header {
      width: 100%;
      padding: 0.5rem 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      min-height: 50px;
      cursor: default;

      .arrow {
        width: 16px;
        height: 16px;
        margin-left: auto;
        background-color: transparent;
        .plus {
          & :first-child > svg {
            transform: rotate(90deg);
            transition: transform 0.2s ease-in;
          }
          svg-helper {
            svg {
              fill: $color1;
            }
          }
        }
        .carrot-arrow {
          width: 100%;
          height: 100%;
          transform: rotate(270deg);
          transition: transform 0.2s ease-in;
        }
      }

      div[header] {
        display: flex;
        align-items: center;
        flex-grow: 1;

        & > svg-helper {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          flex-shrink: 0;

          svg {
            fill: $color1;
          }
        }

        h2 {
          flex-grow: 1;
          font-size: 16px;
          margin: 0;
        }
      }
    }

    .accordion-body {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      @include styled-scrollbar();
    }

    .body-content {
      width: 100%;
      padding: 0.5rem 1rem;

      h4 {
        font-size: 12px;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
    }

    .accordion-list-item {
      display: flex;
      align-items: center;
      border-bottom: 1px dotted $color1;
      padding: 0.5rem 0;

      &:last-child {
        border-bottom: none;
      }

      &.selected {
        position: relative;
        background-color: rgba($color4, 0.3);

        &::before {
          content: ' ';
          width: 0.5rem;
          @include absolute(top 0 bottom 0 left 0);
          background-color: $color4;
          animation: fadeIn 175ms ease-in;
        }
      }

      .item {
        transition: background-color 150ms linear;

        &:hover {
          background-color: rgba($color4, 0.3);
        }
      }

      .decoration,
      favorite-btn {
        flex: 0 0 auto;
      }

      .decoration {
        width: 16px;
        height: 16px;
        margin-right: 12px;

        svg {
          fill: $color1;
        }

        &.favorited svg {
          fill: $color4;
        }
      }

      .text {
        flex: 1 1 auto;

        h3 {
          font-size: 14px;
        }
        p {
          font-size: 11px;
        }
      }

      favorite-btn {
        justify-self: flex-end;
      }
    }

    // Different styles for child accordions
    .accordion-container {
      box-shadow: none;
      .accordion-header {
        padding: 0.5rem 0;
      }
      &.open > .accordion-body {
        border-top: none;
      }
    }
  }
}
