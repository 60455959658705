﻿@import "../../../assets/scss/_master.scss";

paginator {
    
    .pagination {
        display: flex;
        align-items: center;        
        @include user-select(none);
        color: $color3;

        .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 15px;
            cursor: pointer;

            &.hide {
                opacity: 0;
            }

            svg path {
                fill: $color3;
            }
        }
    }
}