@import '../../../assets/scss/_master.scss';

image-upload {
  .images-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    .image-card {
      width: 200px;
      height: 112.5px;
      margin-right: 1rem;
      margin-bottom: 1rem;
      position: relative;
      border: 2px solid rgba(0, 0, 0, 0.555);
      transition: border-color 150ms ease-in;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-color: $color1;
        .btn {
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      .btn {
        @include absolute(top 0 right 0);
        z-index: 2;
        opacity: 0;
        transition: opacity 150ms ease-in;
      }
    }
  }
}
