@import '../../../assets/scss/master';

poi-category {
  ul {
    padding: 0;
    li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      button {
        margin-right: 8px;
      }

      .distance {
        font-size: 12px;
        padding-left: 0.5rem;
        color: $color2;
      }
    }
  }

  .preview-search-btn {
    margin-top: 23px;
    
    label {
      margin-bottom: 0 !important;
    }
  }

  .add-poi-btn {
    margin-right: 8px;
  }

  .standalone {
    display: flex !important;
    margin: 1rem 0;
  }

  .image-upload-modal .modal {
    max-width: 750px !important;
    width: 750px !important;

    .images-container {
      max-height: 40vh;
      overflow-y: auto;
      @include styled-scrollbar();
    }
  }

  .help-text {
    font-style: italic;
    margin-bottom: 10px;
  }
}
