@import '../../../assets/scss/master';

user-search-control {
  input,
  label {
    display: block;
  }

  label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .input-container {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 8px;
    }

    input {
      flex-grow: 1;
    }
  }

  svg-helper {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.mat-autocomplete-panel h4 {
  padding-left: 16px;
}
