@import "../../../assets/scss/master";

.main-section .v2 .bottom .form-box .row .field form-control-projector {
  width: 100%;

  .controls-container {
    .changes {
      background-color: lightyellow;
    }

    .control {
      label {
        font-size: 12px;
      }

      img {
        width: 75px;
        height: 75px;
        object-fit: contain;
        object-position: center center;
        margin: 0 8px 8px 0;
        border: 2px solid lightgray;
        border-radius: 4px;
        padding: 5px;
      }

      .floorplan-list {
        .floorplan-msg-hashtag {
          margin-top: 15px;
          .floorplan-title {
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }

    expanding-set {
      .field {
        margin: 0 8px 8px 0;
        padding-left: 0;

        .label {
          display: none;
        }

        .btn {
          margin-bottom: 0;
        }
      }
    }

    select {
      width: 50%;
    }

    .analyticsConfig {
      label {
        cursor: pointer;
      }

      .is-enabled {
        display: flex;
      }

      .url {
        label {
          margin: 6px 0;
          display: block;
        }

        input[type="text"] {
          display: block;
          @include transition(opacity 150ms ease-in);

          &:disabled {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
