floorplan-specific-social {
    .hashtag-title {
        padding: 8px 0;
        display: flex;
        align-items: center;
    }

    hr {
        margin-top: 15px;
    }
}
