@import '../../../assets/scss/master';

.main-section .v2 .bottom settings-group {
  .form-box {
    margin-bottom: 0;

    .box-header {
      padding-bottom: 0;
    }

    .header-help-text {
      font-size: $content2;
      font-style: italic;
      margin-bottom: 8px;
      padding-left: 0;
      padding-top: 6px;
    }

    .setting {
      margin: 1rem 0;
      position: relative;

      .badge {
        padding: 3px;
        border: 1px solid $color1;
        font-weight: bold;
        border-radius: 3px;
        margin-right: 7px;
        display: inline-flex;
      }

      label {
        display: inline-block;
        padding: 6px;
        padding-left: 0;
        border-radius: 4px;
        margin: 0 !important;
      }

      .help-text {
        font-size: $content2;
        font-style: italic;
        margin-bottom: 8px;
      }

      .field {
        .width-auto {
          width: auto;
        }

        input[type='checkbox'] {
          margin: 0 0 0 5px;
        }

        form-control-projector .controls-container select {
          width: 100%;
        }
      }

      .reset {
        margin-top: 8px;
      }
    }
  }
}
