@import '../../../assets/scss/_master.scss';

add-poi {
  #poiTitle,
  #poiLatitude,
  #poiLongitude {
    &.ng-invalid {
      border: 1px solid red;
    }
  }

  .label {
    text-align: left;
    font-weight: bold;
  }
}
