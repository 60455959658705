@import '../../../assets/scss/master';

.main-section .v2 video-with-preview {
  width: 100%;
  height: 100%;
  background-color: gray;

  img,
  video {
    width: 100%;
    height: 100%;
  }

  .preview {
    @include absolute(top 0 right 0 bottom 0 left 0);
    z-index: 2;

    img {
      object-fit: cover;
    }
  }

  img,
  button {
    opacity: 1;
    transition: opacity 200ms ease;
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }

  button.btn {
    @include align-both-directions();
    z-index: 3;
    border-radius: 50%;
    padding: 1rem;

    .icon {
      margin: 0;
    }
  }
}
