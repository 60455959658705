@import "../../../assets/scss/_master.scss";

.tile {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    @include user-select(none);
    @include relative(bottom 0);
    @include transition(bottom 200ms ease-out);
    box-shadow: 0 4px 7px $color3;

    &:active {
        @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
    }

    &:hover {
        bottom: 5px;

        .body {
            background-color: lighten($pacificBlue, 40%);
        }
    }

    &.selected {
        &:hover {
            .body {
                background-color: lighten($turtleGreen, 20%);
            }
        }

        .header {
            background-color: $turtleGreen;
            justify-content: space-between;

            .icon {
                display: none;
            }

            .label {
                margin-left: 25px;
            }

            .btn {
                margin-right: 6px;
                min-width: 25px;
                min-height: 25px;
                width: 25px;
                height: 25px;
            }
        }
    }

    .header {
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $content1;
        text-transform: uppercase;
        color: $color8;
        background-color: $pacificBlue;

        .icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;

            svg path {
                fill: $color8;
            }
        }
    }

    .body {
        width: 100%;
        height: 165px;
        background-color: $color8;
        @include transition(background-color 200ms ease-in);
        position: relative;

        .base,
        .overlay {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .overlay {
            @include absolute(top 0 left 0);
            @include animation(fadeIn 200ms ease-in);
            z-index: 1;

            &.select {
                background-color: rgba($pacificBlue, 0.7);
            }

            &.build {
                background-color: rgba($turtleGreen, 0.7);
            }

            .icon {
                svg {
                    path,
                    polygon {
                        fill: $color8;
                    }
                }
            }
        }

        .icon {
            width: 40%;
            height: 40%;
            margin-bottom: 10px;

            svg-helper {
                width: 100%;
                height: 100%;
            }

            svg {
                path,
                polygon,
                rect {
                    fill: $color4;
                }
            }
        }

        .label {
            color: $color1;
            font-weight: bold;
            font-size: $content2;
            text-align: center;

            &.long-name {
                white-space: pre-wrap;
                word-break: keep-all;
                width: 100%;
                margin-bottom: -10px;
            }
        }
    }

    .remove-overlay {
        z-index: 2;
        @include absolute(top 0 bottom 0 left 0 right 0);
        background-color: rgba($overlay2, 0.9);
        @include animation(fadeIn 200ms ease-in);
        display: flex;
        justify-content: center;
        flex-direction: column;

        p {
            color: white;
            text-align: center;
        }

        .button-group {
            display: flex;
            justify-content: center;
        }

        .btn {
            flex-direction: column;
            margin-left: 5px;
            margin-right: 5px;
            min-width: 65px;
            min-height: 31px;
            display: flex;
            flex-direction: initial;
            font-size: $content3;
            border: none;
            background-color: transparent;
            @include transition(background-color 150ms ease-in);
            padding: 0;
            justify-content: center;

            &:hover {
                background-color: rgba($color1, 0.5);
            }

            .icon {
                margin: 0;
            }

            .label {
                font-size: 10pt;
                margin-left: 5px;
            }

            &.cancel-btn {
                background-color: $darkBtn;

                &:hover {
                    background-color: rgba($darkBtn, 0.5);
                }
            }
        }
    }

    .disabled-overlay {
        z-index: 2;
        @include absolute(top 0 bottom 0 left 0 right 0);
        background-color: rgba($overlay2, 0.9);
        @include animation(fadeIn 200ms ease-in);

        .message {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 22px 5px;
            opacity: 0;
            @include transition(opacity 200ms ease-in);

            &:hover {
                opacity: 1;
            }

            .icon {
                width: 20px;
                height: 20px;
                margin: auto;
            }

            p {
                color: white;
                text-align: center;
                padding: 0 30px;
                margin-top: 10px;
                margin-bottom: 0;
                font-size: 0.79rem;

                &:last-of-type {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
