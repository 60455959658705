@import '../../../assets/scss/_master.scss';

.progress-container {
  position: relative;

  .progress {
    position: relative;
    padding: 4px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
    min-height: 35px;
    max-width: 500px;
    min-width: 300px;
  }

  .progress-bar {
    height: 25px;
    width: 0;
    border-radius: 4px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05)
    );
    transition: 0.4s linear;
    transition-property: width, background-color;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
    background-color: #f27011;
  }

  .percentage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .message {
    @include align-horizontally();
    top: -20px;
    white-space: nowrap;
  }
}
