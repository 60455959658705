@import '../../../assets/scss/master';

back-arrow {
  .back-arrow {
    vertical-align: middle;
    margin-left: -40px; //offsets padding of .top class
    width: 50px;
    height: 100%;
    border-right: 2px solid $rowcolor4;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      .icon svg path {
        fill: $color1;
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      margin: 0;

      svg path {
        fill: $color3;
        @include transition(fill 175ms ease-in);
      }
    }
  }
}
