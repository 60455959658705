import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { GraphicsModel } from './data.structures';
import { Dropdown } from './dropdown.component';

export enum UiAction {
  Open
}

export interface IUiMessage {
  Name: string;
  Action: UiAction;
}

@Injectable({
  providedIn: 'root'
})
export class UiHelperService {
  private _dropdowns: Dropdown[];

  private messagesSubject = new Subject<IUiMessage>();

  constructor() {
    this._dropdowns = new Array<Dropdown>();
  }

  get messages$(): Observable<IUiMessage> {
    return this.messagesSubject.asObservable();
  }

  sendMessage(message: IUiMessage) {
    this.messagesSubject.next(message);
  }

  registerDropdown(dd: Dropdown) {
    this._dropdowns.push(dd);
  }

  unregisterDropdown(dd: Dropdown) {
    const index = this._dropdowns.indexOf(dd);
    if (index > -1) this._dropdowns.splice(index, 1);
  }

  closeAllDropdowns() {
    this._dropdowns.forEach(dd => (dd.isOpen = false));
  }

  selectText(element: HTMLElement) {
    if ((document.body as any).createTextRange) {
      const range = (document.body as any).createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn('Could not select text in node: Unsupported browser.');
    }
  }

  addClassName(element: HTMLElement | SVGElement, className: string) {
    if (element.classList) {
      element.classList.add(className);
    } else {
      const classAttribute = element.getAttribute('class') || '';
      const classList: string[] = classAttribute.split(' ');

      if (!classList.find(x => x === className)) {
        classList.push(className);
      }

      element.setAttribute('class', classList.join(' ').trim());
    }
  }

  removeClassName(element: HTMLElement | SVGElement, className: string) {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      const classAttribute = element.getAttribute('class') || '';
      let classList: string[] = classAttribute.split(' ');

      classList = classList.filter(x => x !== className);

      element.setAttribute('class', classList.join(' ').trim());
    }
  }

  toggleClassName(element: HTMLElement, className: string, duration: number) {
    this.addClassName(element, className);
    setTimeout(() => {
      this.removeClassName(element, className);
    }, duration);
  }

  // uses transform GM, initial scale, viewport, and content elements to compute the final values which need to be applied in DOM
  computeFinalGM(
    transGM: GraphicsModel,
    initialScale: number,
    viewport: Element,
    content: Element
  ): GraphicsModel {
    const vpDims = this.tryGetBaseDims(viewport);
    const cnDims = this.tryGetBaseDims(content);

    // combined scale
    const fullScale = transGM.scale * initialScale;

    // trans coords plus offset to keep content centered
    const x = transGM.x + (vpDims.width - cnDims.width * fullScale) / 2;
    const y = transGM.y + (vpDims.height - cnDims.height * fullScale) / 2;

    return new GraphicsModel({ x, y, scale: fullScale });
  }

  // compares viewport and content and determines the values necessary to fit the content to viewport area
  determineInitialGraphicsModel(viewport: Element, content: Element): GraphicsModel {
    const vpDims = this.tryGetBaseDims(viewport);
    const cnDims = this.tryGetBaseDims(content);

    const scaleX = vpDims.width / cnDims.width;
    const scaleY = vpDims.height / cnDims.height;

    const scale = Math.min(scaleX, scaleY);

    const x = (vpDims.width - cnDims.width * scale) / 2;
    const y = (vpDims.height - cnDims.height * scale) / 2;

    return new GraphicsModel({ x, y, scale });
  }

  // compares width/height ratio differences and returns smaller of the two (for a contain scale, not cover)
  determineInitialScale(viewport: Element, content: Element): number {
    const vpDims = this.tryGetBaseDims(viewport);
    const cnDims = this.tryGetBaseDims(content);

    const scaleX = vpDims.width / cnDims.width;
    const scaleY = vpDims.height / cnDims.height;

    const scale = Math.min(scaleX, scaleY);

    return scale;
  }

  // attempt to get base dimensions of element by checking for baseVals and attributes, fallbacks to clientWidth/clientHeight
  tryGetBaseDims(element: Element): { width: number; height: number } {
    const retval = { width: 0, height: 0 };

    // In some cases the element is null, if it is null, this blows up. This is mostly in IE.
    // It's fixed by fixing the place that's parsing the element to pass in, but this prevents the entire app from crashing.
    if (element) {
      const width: SVGAnimatedLength = element['width'];
      if (width && width.baseVal) {
        retval.width = width.baseVal.value;
      } else if (element.hasAttribute('width')) {
        retval.width = +element.getAttribute('width').replace('px', '');
      } else {
        retval.width = element.clientWidth;
      }

      const height: SVGAnimatedLength = element['height'];
      if (height && height.baseVal) {
        retval.height = height.baseVal.value;
      } else if (element.hasAttribute('height')) {
        retval.height = +element.getAttribute('height').replace('px', '');
      } else {
        retval.height = element.clientHeight;
      }
    }

    return retval;
  }
}

export function sortByName(a: { Name: string }, b: { Name: string }) {
  if (!a || !a.Name) return 1;
  if (!b || !b.Name) return -1;

  return a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1;
}

export function sortObjectByNameAndOrder(key: string, order = SortDirection.Ascending) {
  return function sorter(a, b) {
    if (!a || !a.hasOwnProperty(key)) return 1;
    if (!b || !b.hasOwnProperty(key)) return -1;

    const values = parseValues(a[key], b[key]);

    const comparison = values.A < values.B ? -1 : 1;

    return order === SortDirection.Descending ? comparison * -1 : comparison;
  };
}

export function sortByPublicNameOrTitle(
  a: { PublicName: string; Title: string },
  b: { PublicName: string; Title: string }
) {
  const aName = a && (a.PublicName || a.Title);
  const bName = b && (b.PublicName || b.Title);

  if (!aName) return 1;
  if (!bName) return -1;
  return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1;
}

export function sortByPublicNameOrName(
  a: { PublicName: string; Name: string },
  b: { PublicName: string; Name: string }
) {
  const aName = a && (a.PublicName || a.Name);
  const bName = b && (b.PublicName || b.Name);

  if (!aName) return 1;
  if (!bName) return -1;
  return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1;
}

export function sortObjectsByMultiplePropertiesAndOrder(
  order = SortDirection.Ascending,
  key1: string,
  key2: string,
  key3?: string
) {
  return function multiSort(a, b) {
    if (!a || !a.hasOwnProperty(key1)) return 1;
    if (!b || !b.hasOwnProperty(key1)) return -1;

    let values = parseValues(a[key1], b[key1]);
    if (values.A === values.B) {
      values = parseValues(a[key2], b[key2]);
    }

    if (key3 && values.A === values.B) {
      values = parseValues(a[key3], b[key3]);
    }

    if (values.A === values.B) {
      return 0;
    } else {
      const comparison = values.A < values.B ? -1 : 1;

      return order === SortDirection.Descending ? comparison * -1 : comparison;
    }
  };
}

function parseValues(val1, val2) {
  let A;
  let B;
  if (!Number.isNaN(+val1) && !Number.isNaN(+val2)) {
    A = +val1;
    B = +val2;
  } else if (typeof val1 === 'string' && typeof val2 === 'string') {
    A = val1.toLowerCase();
    B = val2.toLowerCase();
  } else {
    A = val1 === null ? '' : val1;
    B = val2 === null ? '' : val2;
  }

  return { A, B };
}

export function sortByNameInNestedObjectWithOrder(
  a: { Name: string },
  b: { Name: string },
  direction = SortDirection.Ascending
) {
  const aName = a && a.Name;
  const bName = b && b.Name;

  if (!aName) return 1;
  if (!bName) return -1;
  const comparison = aName.toLowerCase() < bName.toLowerCase() ? -1 : 1;

  return direction === SortDirection.Descending ? comparison * -1 : comparison;
}

export function sortByBaseTotalSquareFeet(
  a: { BaseTotalSquareFeet: number },
  b: { BaseTotalSquareFeet: number }
) {
  if (!a) return 1;
  if (!b) return -1;
  return a.BaseTotalSquareFeet - b.BaseTotalSquareFeet;
}

export function createStringSorterByPropertyName(propName: string) {
  return (a: any, b: any): 1 | -1 => {
    if (!a || !a[propName]) return 1;
    if (!b || !b[propName]) return -1;

    return a[propName].toLowerCase() < b[propName].toLowerCase() ? -1 : 1;
  };
}

export function createNumberSorterByPropertyName(propName: string) {
  return (a: any, b: any): number => {
    if (!a || !a[propName]) return 1;
    if (!b || !b[propName]) return -1;

    return a[propName] - b[propName];
  };
}

/**
 * Sort using a list of pre-sorted strings and sort any values
 * not in the pre-sorted list at the end in the order they came in
 *
 * @param propName Name of the property to sort on
 * @param order Array of ordered string values
 */
export function createStringSorterByPropertyNameUsingSpecificOrder(
  propName: string,
  order: string[]
) {
  return (a: any, b: any): 1 | -1 => {
    if (!a || !a[propName]) return 1;
    if (!b || !b[propName]) return -1;

    const aIndex = order.indexOf(a[propName]);
    const bIndex = order.indexOf(b[propName]);

    return aIndex === -1 ? 1 : bIndex === -1 ? -1 : aIndex < bIndex ? -1 : 1;
  };
}

export function sortByTitle(a: { Title: string }, b: { Title: string }) {
  if (!a || !a.Title) return 1;
  if (!b || !b.Title) return -1;

  return a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : 1;
}

export function sortBySortOrder(a: { SortOrder: number }, b: { SortOrder: number }) {
  return a.SortOrder - b.SortOrder;
}

export function sortByExistingOrder() {
  return 1;
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc'
}
