@import '../../../assets/scss/_master.scss';

progress-tracker {
  $stepColor: $color3;
  --completed-color: #{$color4};
  $completedColor: var(--completed-color);
  $errorColor: $errorText;

  display: flex;
  font-size: 18px;
  filter: drop-shadow(0 4px 7px lighten($color3, 20%));

  .step {
    padding: 0.667em 0.667em 0.667em 1.333em;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110%;
    margin-left: -15px;
    $raisedGradient: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(241, 241, 241, 0.5) 50%,
      rgba(225, 225, 225, 0.5) 51%,
      rgba(246, 246, 246, 0.1) 100%
    );

    transition: 200ms background-color ease-in;
    background-color: $stepColor;
    background-image: $raisedGradient,
      linear-gradient(
        to right,
        rgba(0, 0, 0, 0.1),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.3)
      );

    &.completed {
      background-color: $completedColor;
    }

    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);

    &:first-child {
      clip-path: polygon(90% 0px, 100% 50%, 90% 100%, 0% 100%, 0% 50%, 0% 0%);
      border-radius: 10px 0 0 10px;
      margin-left: 0;
    }

    &:last-child {
      clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
      border-radius: 0 10px 10px 0;
    }
    .bell{
      height: 18px;
      width: 15px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .svg-container{
        width: 100%;
        
      }
      &:hover{
        .modal-pointer{
          height: 0;
          width:0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 25px solid #2f323a;
          margin-top: 5px;
        }
        svg{
          fill: white;
        }
      }
    }
  }
}
