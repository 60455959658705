@import '../../../assets/scss/_master.scss';

tooltip {
  .icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    display: inline-block;

    &.margin-both-sides {
      margin: 0 0.5rem;
    }

    .info path {
      fill: $color4 !important;
    }

    .extra-cost path {
      fill: $turtleGreen !important;
    }

    .error {
      path,
      ellipse {
        fill: $errorText !important;
      }
    }

    .progressinfo path {
      fill: $color1 !important;
    }
  }
}
