﻿@import "_mixins.scss";

$navBarExpandedWidth: 150px;
$navBarCollapsedWidth: 70px;
$globalControlBarHeight: 50px;
$loginMenuWidth: 400px;

$color1: #2f323a;
$color2: #5b5d65;
$color3: #95989a;
$color4: #f3b544;
$darkColor4: #dba33d;
$color5: #f2f7f6;
$color6: #435072;
$color7: #7b95d9;
$color8: #fff;
$color10: #000;
$color11: #60bda9;
$color12: #e1e1e1;
$color13: #bcbcbc;
$color14: #d8dbda;
$success: #54bca8;

$pacificBlue: #2888ca;
$turtleGreen: #4caf50;
$warningYellow: #f0b34b;
$darkBtn: #262626;

$boxshadowcolor1: #d0d0d0;
$modalBackgroundColor: rgba(0, 0, 0, 0.6);
$overlay2: #42454c;

$rowcolor1: #f0f0f0;
$rowcolor2: #e0e0e0;
$rowcolor3: #eef3f6;
$rowcolor4: #e4e4e4;

$errorText: #ff3b3b;

$product-title: 90px;
$headline: 22px;
$sub-head1: 32px;
$sub-head2: 20px;
$content1: 16px;
$content2: 14px;
$content3: 10px;
