﻿@mixin background-image($urlPath) {
  background-image: $urlPath;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

@mixin box-shadow($box-shadow...) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}

@mixin user-select($selection) {
  -webkit-user-select: $selection;
  -moz-user-select: $selection;
  -ms-user-select: $selection;
  user-select: $selection;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  animation: $animation;
}

@mixin animation-duration($animation-duration) {
  -webkit-animation-duration: $animation-duration;
  -moz-animation-duration: $animation-duration;
  animation-duration: $animation-duration;
}

@mixin animation-delay($animation-delay) {
  -webkit-animation-delay: $animation-delay;
  -moz-animation-delay: $animation-delay;
  animation-delay: $animation-delay;
}

@mixin animation-iteration-count($animation-iteration-count) {
  -webkit-animation-iteration-count: $animation-iteration-count;
  -moz-animation-iteration-count: $animation-iteration-count;
  animation-iteration-count: $animation-iteration-count;
}

@mixin animation-fill-mode($animation-fill-mode) {
  -webkit-animation-fill-mode: $animation-fill-mode;
  -moz-animation-fill-mode: $animation-fill-mode;
  animation-fill-mode: $animation-fill-mode;
}

@mixin animation-name($animation-name) {
  -webkit-animation-name: $animation-name;
  -moz-animation-name: $animation-name;
  animation-name: $animation-name;
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

@mixin transform-origin($transform-origin) {
  -webkit-transform-origin: $transform-origin;
  -moz-transform-origin: $transform-origin;
  -ms-transform-origin: $transform-origin;
  transform-origin: $transform-origin;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
  -moz-transition-property: $transition-property;
  transition-property: $transition-property;
}

@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
  -moz-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}

@mixin transition-timing-function($transition-timing-function) {
  -webkit-transition-timing-function: $transition-timing-function;
  -moz-transition-timing-function: $transition-timing-function;
  transition-timing-function: $transition-timing-function;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

// positioning mixins
@mixin absolute($args) {
  @include position(absolute, $args);
}

@mixin fixed($args) {
  @include position(fixed, $args);
}

@mixin relative($args) {
  @include position(relative, $args);
}

@mixin position($position, $args) {
  position: $position;
  // list of offsets to check for in $args
  $offsets: top right bottom left;

  // loop through $offsets
  @each $o in $offsets {
    // if current offset found in $args
    // assigns its index to $i or 'false' if not found
    $i: index($args, $o);

    @if $i
      // offset in $args?
      and
      $i +
      1 <=
      length($args)
      // offset value in range?
      and
      type-of(nth($args, $i + 1)) ==
      number
    {
      // offset value vaid?
      // assign offset value
      #{$o}: nth($args, $i + 1);
    }
  }
}

@mixin align-vertically($position: absolute) {
  // assumes position absolute if none passed
  position: $position;
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin align-horizontally() {
  @include absolute(left 50%);
  @include transform(translateX(-50%));
}

@mixin align-both-directions() {
  @include absolute(top 50% left 50%);
  @include transform(translate(-50%, -50%));
}

// border radius functions
@mixin border-top-radius($radii) {
  border-top-left-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-right-radius($radii) {
  border-bottom-right-radius: $radii;
  border-top-right-radius: $radii;
}

@mixin border-bottom-radius($radii) {
  border-bottom-left-radius: $radii;
  border-bottom-right-radius: $radii;
}

@mixin border-left-radius($radii) {
  border-bottom-left-radius: $radii;
  border-top-left-radius: $radii;
}

@mixin styled-scrollbar() {
  &::-webkit-scrollbar {
    width: 16px;
  }
  
  &::-webkit-scrollbar-track {
    border: solid 5px transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgb(35, 35, 35, 0.35);
    border: solid 5px transparent;
    border-radius: 1rem;
  }
}

@mixin flex-center-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-between() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin animation-fadeIn($speed: 250ms, $timingFunction: ease) {
  animation: fadeIn $speed $timingFunction;
}
