icon-form-control {
  position: relative;
  display: inline-block;

  &:hover {
    .trash {
      opacity: 1;
    }
  }

  .trash {
    opacity: 0;
    transition: opacity 200ms ease;
    position: absolute;
    top: 0;
    left: 75px;
  }

  #icon-picker-modal .modal {
    max-width: 800px;
    width: 800px;
  }

  .icon-preview {
    width: 75px;
    height: 75px;
    margin: 0 8px 8px 0;
    border: 2px solid lightgray;
    border-radius: 4px;
    padding: 5px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
