@import '../../../assets/scss/master';

.form-box.tag-remover {
  overflow: hidden;

  .box-header {
    display: flex;
    justify-content: space-between;
    .right-header {
      display: flex;
      align-items: center;
      .tag-count {
        display: flex;
        margin-right: 20px;
        svg-helper {
          width: 15px;
          margin-right: 5px;
          @include transform(scaleX(-1));
          fill: $color8;
        }
      }
      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        @include transform(rotate(-90deg) scale(1));
        @include transition(transform 200ms ease-out);
        &.expanded {
          @include transform(rotate(-90deg) scale(-1));
        }
        svg-helper {
          width: 10px;
          path {
            fill: $color8;
          }
        }
      }
    }
  }

  .box-content {
    padding: 20px;
    .tag {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      min-width: 125px;
      margin: 0 15px 15px 0;
      padding: 5px 0 5px 10px;
      background-color: $color1;
      color: $color8;
      border-radius: 0.5rem;
    }
    button.btn.quick {
      svg {
        fill: $color8;
      }
    }
  }
}
