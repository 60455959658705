<div class="form-box">
  <button *ngIf="mediaVMs.length" class="btn standard-positive margin-bottom-sm" (click)="sortMediaByTitle()">Sort by Title</button>
  <p *ngIf="mediaVMs.length && showLatestModified && latestModifiedDate" class="date-modified">Last modified on: {{latestModifiedDate}}</p>
  <div cdkDropList class="flex-column" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let item of mediaVMs; let i = index; trackBy: trackByIndex">
      <div *ngIf="!item.MarkedForDeletion" cdkDrag [cdkDragDisabled]="config.IsReadOnly" class="row">
        <button *ngIf="!config.IsReadOnly" drag-handle cdkDragHandle class="btn no-bkg drag-handle" matTooltip="Drag to reorder">
          <div class="icon">
            <svg-helper svgName="drag_handle"></svg-helper>
          </div>
        </button>
        <div *cdkDragPreview class="drag-preview">
          <button class="btn no-bkg drag-handle">
            <div class="icon">
              <svg-helper svgName="drag_handle"></svg-helper>
            </div>
          </button>
          <h4>{{item.Title}}</h4>
        </div>
        <div *cdkDragPlaceholder class="drag-placeholder"></div>
        <div class="item">
          <div class="label">{{item.Title}}</div>
        </div>
        <button class="btn quick standard-positive" [disabled]="item.NeedsSave" (click)="togglePreview(item)" matTooltip="Preview">
          <span class="icon">
            <svg-helper svgName="mini_preview"></svg-helper>
          </span>
        </button>
        <button class="btn quick standard-positive"
                matTooltip="Edit" (click)="editMedia(i)">
          <span class="icon">
            <svg-helper svgName="pencil_box"></svg-helper>
          </span>
        </button>
        <button *ngIf="allowImageDownload" class="btn quick standard-positive" [disabled]="item.NeedsSave || !item.ImageVM"
                matTooltip="{{downloadTooltipText || 'download'}}" (click)="downloadMedia(item)">
          <span class="icon">
            <svg-helper svgName="download"></svg-helper>
          </span>
        </button>
        <button class="btn quick standard-negative" matTooltip="Delete" [disabled]="!item.AllowDelete || config.IsReadOnly" (click)="deleteMedia(i)">
          <span class="icon">
            <svg-helper svgName="trash_can"></svg-helper>
          </span>
        </button>
        <div *ngIf="config.IsPrimaryCheckbox" class="field primary-checkbox">
          <input [ngModel]="item.IsPrimary" [disabled]="primaryCheckboxDisabled" (ngModelChange)="handlePrimaryImageSelection($event, item)"
                 type="checkbox" id="media-checkbox={{i}}" matTooltip="When checked, this image will be shown in the SCP and hidden from the IFP.">
        </div>
        <tooltip *ngIf="!item.AllowDelete" message="To delete this media, first delete any hotspots that it is assigned to."></tooltip>
      </div>
    </ng-container>
    <div *ngIf="!hasMedia">No media yet</div>
  </div>
</div>
<div class="form-box">
  <div class="box-content">
    <div class="row">
      <div class="col-xs-6 field media-form">
        <div *ngIf="!config.OnlyImages" class="col-xs-12 row">
          <div class="label">Media Type
          </div>
          <select [(ngModel)]="mediaItem.MediaType" [disabled]="!!mediaItem.Id || config.IsReadOnly">
            <option *ngIf="mediaItem.MediaType === mediaType.Unspecified" [ngValue]="mediaType.Unspecified">Unspecified</option>
            <option *ngIf="mediaItem.MediaType === mediaType.Pano" [ngValue]="mediaType.Pano">Pano</option>
            <option [ngValue]="mediaType.VRTour">VR Tour</option>
            <option [ngValue]="mediaType.Static">Static</option>
            <option *ngIf="mediaItem.MediaType === mediaType.Matterport" [ngValue]="mediaType.Matterport">Matterport</option>
            <option *ngIf="mediaItem.MediaType === mediaType.Opto" [ngValue]="mediaType.Opto">Opto</option>
            <option *ngIf="mediaItem.MediaType === mediaType.Video" [ngValue]="mediaType.Video">Video</option>
            <option [ngValue]="mediaType.MLPanoramic">External URL</option>
          </select>
        </div>
        <div class="col-xs-12 row">
          <div class="label">Title
          </div>
          <input type="text" name="Title" [disabled]="config.IsReadOnly" [(ngModel)]="mediaItem.Title" />
        </div>

        <div *ngIf="config.ShowCUIDField" class="col-xs-12 row">
          <div class="label">Client Unique Identifier</div>
          <input type="text" name="CUID" [disabled]="config.IsReadOnly" [(ngModel)]="mediaItem.ClientUniqueIdentifier" />
        </div>
        <div *ngIf="config.ShowCost" class="col-xs-12 row">
          <div class="label">Cost</div>
          <input type="number" name="Cost" [disabled]="config.IsReadOnly" [(ngModel)]="mediaItem.Cost" />
        </div>

        <div *ngIf="!isFileMediaType(mediaItem)" class="col-xs-12 row">
          <div class="label"><span class="warning">*</span>External URL<tooltip message="Only HTTPS links are supported."></tooltip>
          </div>
          <input type="text" name="ExternalUrl" [disabled]="config.IsReadOnly" [(ngModel)]="mediaItem.ExternalUrl" />
        </div>
        <div *ngIf="isFileMediaType(mediaItem) && !config.IsReadOnly" class="col-xs-12 row flex-column">
          <div class="label"><span class="warning">*</span>Image Upload<tooltip [message]="mediaItem.MediaType === mediaType.VRTour ? 'Only panoramic JPG files are supported.' : 'Files in JPG, PNG, BMP, or GIF formats are supported.'"></tooltip>
          </div>
          <image-upload [images]="mediaItem.ImageVM ? [mediaItem.ImageVM] : []" [hidePreview]="mediaItem.NeedsSave" [maxImagesAllowed]="1" [maxFileSizeMb]="maxFileSizeMb" [allowDelete]="false" [showDropzone]="false" [allowedFileExts]="['.jpg', '.jpeg', '.png', '.bmp', '.gif']" (imagesChange)="handleImagesChange($event, mediaItem)"></image-upload>
        </div>
        <div *ngIf="!config.IsReadOnly" class="col-xs-12">
          <button class="btn standard-positive quick-add-btn" (click)="addMedia()">{{editEnabled ? 'Finish Edit': 'Add'}}</button>
        </div>
      </div>
      <div *ngIf="!config.IsReadOnly" class="col-xs-6 field">
        <div class="label">Bulk Upload</div>
        <file-input-form [maxFileSizeMb]="maxFileSizeMb" [allowedFileExts]="['.jpg', '.jpeg', '.png', '.bmp', '.gif']" (filesChange)="handleBulkUploadChange($event)"></file-input-form>
      </div>
    </div>
  </div>
</div>
<modal id="preview" *ngIf="previewItem" (close)="togglePreview()">
  <div *ngIf="!!previewItem.ImageVM?.PreviewUrl" class="image" [style.background-image]="previewItem.ImageVM.PreviewUrl | safeBackgroundImageUrl"></div>
  <iframe *ngIf="!!previewItem.ExternalUrl" [src]="previewItem.ExternalUrl | safeUrl"></iframe>
</modal>