@import '../../../assets/scss/_master.scss';

percentage-bar {
  display: block;
  --percentage-bar-color: #{$color4};

  .percentage-bar-container {
    border-radius: 6px;
    border: 1px solid $color3;

    .percentage-bar {
      min-height: 4px;
      width: 0;
      border-radius: 6px;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.05)
      );
      transition: 0.4s linear;
      transition-property: width, background-color;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
      background-color: var(--percentage-bar-color);
    }
  }
}
