@import '../../../assets/scss/_master.scss';

.main-section .feature-content accordion-form {
  &.dark {
    .form-row.expanded {
      .body {
        background-color: $color1;

        div[body] {
          padding-bottom: 30px;
        }
      }
    }
  }

  .form-row {
    width: 100%;
    position: relative;
    @include animation(fadeIn 175ms ease-out);
    box-shadow: 0 4px 7px $color3;
    border-radius: 6px;

    &.expanded {
      .header {
        @include border-bottom-radius(0);

        .button-group {
          .arrow {
            .icon {
              @include transform(rotate(270deg) scale(-1));

              svg path {
                fill: $color4;
              }
            }
          }
        }
      }

      .body {
        border-width: 2px;
      }
    }

    &.dirty {
      .header,
      .body {
        border-color: $color4;
      }

      &:not(.expanded) {
        .body {
          border-color: transparent;
        }
      }
    }

    &.invalid {
      .header,
      .body {
        border-color: $errorText;
      }

      &:not(.expanded) {
        .body {
          border-color: transparent;
        }
      }
    }

    .header {
      background-color: $color8;
      width: 100%;
      height: 60px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      border: 2px solid $rowcolor4;
      @include box-sizing(border-box);
      position: relative;
      @include transition(border-color 250ms ease-out);
      cursor: pointer;

      div[header-title] {
        display: flex;
        align-items: center;
        width: 50%;

        & > .title {
          max-width: 75%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .icon.large {
        width: 35px;
        height: 35px;
        margin: 0 10px 0 30px;
        flex-shrink: 0;

        svg {
          path,
          polygon,
          circle,
          rect {
            fill: $color4;
          }
        }
      }

      .badge {
        text-transform: uppercase;
        font-size: $content2;
        padding: 6px 8px;
        border: 1px solid;
        border-radius: 4px;
        margin: 0 8px;
        font-weight: bold;
        width: 82px;
        text-align: center;
        flex-shrink: 0;

        &.gold {
          color: $color4;
          border-color: $color4;
        }

        &.dark {
          color: $color1;
          border-color: $color1;
        }

        &.blue {
          color: $pacificBlue;
          border-color: $pacificBlue;
        }
      }

      .title {
        font-size: $sub-head2;
        margin: 0;
      }

      .button-group {
        @include absolute(right 0 top 0);
        display: inline-flex;
        align-items: center;
        height: 100%;

        .btn {
          margin-left: 15px;
          @include animation(fadeIn 200ms ease-out);
        }

        .arrow {
          width: 60px;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-left: 2px solid $rowcolor4;
          margin-left: 15px;
          cursor: pointer;

          .icon {
            width: 20px;
            height: 20px;
            margin: 0;
            @include transform(rotate(270deg) scale(1));
            @include transition(transform 300ms ease-out);
          }
        }
      }
    }

    .body {
      overflow: hidden;
      @include border-bottom-radius(6px);
      padding: 0 20px;
      border: 0 solid $rowcolor4;
      border-top: none;

      div[body] {
        padding-top: 30px;

        form {
          & > .row {
            flex-grow: 1;
          }
        }
      }

      .quick-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .title {
          color: $color8;
          font-size: $content1;
          margin: 0;
          font-weight: normal;
        }

        .btn {
          margin: 10px;
        }
      }
    }
  }
}
