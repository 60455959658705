@import '../../../assets/scss/master';

up-down-arrows {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .up,
  .down {
    cursor: pointer;

    svg {
      width: 10px;
      height: 10px;

      * {
        fill: $color1;

        &:hover {
          fill: lighten($color: $color1, $amount: 0.25);
        }
      }
    }
  }

  .disabled {
    .up,
    .down {
      cursor: auto;

      svg * {
        fill: $color13;
      }
    }
  }

  .up {
    transform: rotate(-90deg);
  }

  .down {
    transform: rotate(90deg);
  }
}
