image-slider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .images-container {
        width: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-wrap: nowrap;
        transform: translateX(0);
        transition: transform 150ms linear;

        .image {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: 100%;
            flex-shrink: 0;
        }
    }

    paginator {
        flex: 0 0 auto;
        margin-top: 1rem;
    }
}
