@import '../../../assets/scss/master';

tag-counter {
  display: flex;
  align-items: center;
  background-color: $color1;
  color: $color8;
  font-size: 16px;
  padding: 5px;
  border-radius: 4px;
  margin-left: auto;

  svg-helper {
    width: 16px;
    height: 16px;
    display: block;
    margin-right: 10px;
    fill: $color8;
  }
}
